<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { isMobile } from "@/utils/utils";
export default {
  data() {
    return {};
  },
  beforeUpdate() {
    if (isMobile() && this.$route.path.split("/")[1] == "pc") {
      this.$router.push({
        name: "h5home",
      });
    } else if (!isMobile() && this.$route.path.split("/")[1] != "pc") {
      this.$router.push({
        name: "pc",
      });
    }
  },
};
</script>
<style lang="scss">
html {
  background-color: #f4f4f4;
  height: 100%;
}
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
</style>
