export default [
    {
        path: '/',
        name: 'h5home',
        components: {
            default: () => import('@/views/H5Home/H5Home'),
        },
    },
    {
        path: '/seekAdviceFrom',
        name: 'seekAdviceFrom',
        components: {
            default: () => import('@/views/H5Home/seekAdviceFrom'),
        },
    },

]