export default [
    {
        path: '/pc',
        name: 'pc',
        redirect: {
            name: 'index'
        },
        components: {
            default: () => import('@/views/PcHome/PcAll/PcAll'),
        },
        children: [
            {
                path: 'home',
                name: 'pcHome',
                components: {
                    default: () => import('@/views/PcHome/HomePage/HomePage'),
                },
                children: [
                    {
                        path: 'index',
                        name: 'index',
                        components: {
                            default: () => import('@/views/PcHome/PcHome'),
                        },
                    },
                ]
            }
        ]
    }
]