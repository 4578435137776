import Vue from 'vue'
import VueRouter from 'vue-router'
import h5Route from './h5route'
import pcRoute from './pcRoute'

Vue.use(VueRouter)
const origiinalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return origiinalPush.call(this, location).catch(err => err)
}
const routes = [
  ...h5Route,
  ...pcRoute
]

const router = new VueRouter({
  mode:'hash',
  routes,
  scrollBehavior: ()=>({y:0}) 
})

export default router
